import {
  faArrowRight,
  faKey,
  faUnlockAlt,
  faUserHeadset
} from '@fortawesome/pro-regular-svg-icons'
import { Button, Icon, Input } from '@intility/bifrost-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './home.module.css'

const Home = () => {
  const { t } = useTranslation()

  const [sessionKey, setSessionKey] = useState<string | null>(null)

  const [errorMessage, setErrorMessage] = useState<string>()

  const handleSessionKeyInput = (input: string) => {
    checkIsNumber(input)
    const limitedNumber = limitToSevenDigits(input)
    setSessionKey(limitedNumber)
  }

  const limitToSevenDigits = (numberString: string) => {
    const MAX_DIGITS = 7

    numberString = numberString.replaceAll(/[^0-9]/g, '')

    return numberString.slice(0, MAX_DIGITS)
  }

  const validateKey = (key: string) => {
    if (key.length < 7) {
      setErrorMessage(
        t('Please enter the 7 numbered code received from support')
      )
    }
  }

  const checkIsNumber = (input: string) => {
    if (/[^0-9]/.test(input)) {
      setErrorMessage(t('Numbers Only'))
    } else {
      setErrorMessage(undefined)
    }
  }

  return (
    <div
      style={{
        margin: '0 auto',
        maxWidth: '800px',
        height: 'calc(100vh - 56px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '24px'
      }}
    >
      <div className='bfc-base-2-bg' style={{ padding: '15px 35px 35px 35px' }}>
        <div style={{ textAlign: 'center' }}>
          <h3 className='bf-h3'>Remote Support</h3>
          <p className='bf-p' style={{ color: 'var(--bfc-base-c-2)' }}>
            {t('Please type in session key received from Intility Support')}
          </p>
        </div>
        <form
          autoComplete='off'
          action='https://rs.intility.com/api/start_session?'
          method='GET'
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Input
              autoComplete='off'
              id='numbers'
              pattern='\d{7}'
              name='short_key'
              required
              style={{}}
              placeholder={t('7 digits')}
              label=''
              hideLabel
              clearable
              variant={errorMessage === undefined ? 'outline' : 'basic'}
              value={sessionKey || ''}
              onKeyDown={e => {
                if (e.key === 'Enter' && sessionKey) {
                  validateKey(sessionKey)
                }
              }}
              onChange={e => handleSessionKeyInput(e.target.value)}
              state={errorMessage !== undefined ? 'alert' : 'default'}
              feedback={errorMessage !== undefined ? errorMessage : false}
            />
            <Button
              style={{ width: '100%' }}
              variant='filled'
              // state='theme'
              type='submit'
              onClick={() => sessionKey && validateKey(sessionKey)}
            >
              {t('Start session')} 
              <Icon style={{ marginLeft: '5px' }} icon={faUnlockAlt} />
            </Button>
          </div>
        </form>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          marginTop: '12px'
        }}
      >
        <div style={{ flex: '1 1' }}>
          <a
            style={{ textDecoration: 'none' }}
            href='https://reset.intility.no'
          >
            <div className={style.wrapper}>
              <div className={style.label + style.header}>
                <div className={style.header}>
                  <div
                    style={{
                      height: '120px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Icon
                      icon={faKey}
                      size='2x'
                      style={{
                        color: 'var(--bfc-base-c-2)'
                      }}
                    />
                  </div>
                  <div className={style.content}>
                    <div className={style.labelSpan}>
                      <p className='bf-p'> {t('Reset Password')}</p>

                      <Icon icon={faArrowRight} />
                    </div>

                    {t(
                      'In need of a new password? Go to the Password reset website.'
                    )}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div style={{ flex: '1 1' }}>
          <a
            style={{ textDecoration: 'none' }}
            href='https://portal-beta.intility.com/en-us'
          >
            <div className={style.wrapper}>
              <div className={style.label + style.header}>
                <div className={style.header}>
                  <div
                    style={{
                      height: '120px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Icon
                      icon={faUserHeadset}
                      size='2x'
                      style={{
                        color: 'var(--bfc-base-c-2)'
                      }}
                    />
                  </div>
                  <div className={style.content}>
                    <div className={style.labelSpan}>
                      <p className='bf-p'> Self-Service Support</p>

                      <Icon icon={faArrowRight} />
                    </div>

                    {t(
                      'Visit Intility portal for information on self-service functionality and other useful guides.'
                    )}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Home
