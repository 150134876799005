import { Nav, Checkbox } from '@intility/bifrost-react'
import '@intility/bifrost-react/dist/bifrost-app.css'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { replaceLocale } from 'utils/locale'
import styles from './navigation.module.css'

const Navigation: React.FC = ({ children }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { locale } = useParams()
  const { pathname } = useLocation()

  return (
    <Nav
      appName='Support'
      logoOnClick={e => {
        e.preventDefault()
        navigate('/')
      }}
      top={
        <>
          {/* <Nav.Group name={<Icon icon={faGlobe} size="lg" />}></Nav.Group> */}
          <Nav.Group name='Language'>
            {/* <Nav.Item icon={faUser}>Language</Nav.Item> */}
            <div className={styles.section}>
              <div className={styles.header}>{t('Language')}</div>
              <Checkbox
                type='radio'
                name='language'
                label='English'
                checked={locale === 'en-us'}
                onChange={() => navigate(replaceLocale(pathname, 'en-us'))}
              />
              <Checkbox
                type='radio'
                name='language'
                label='Norsk'
                checked={locale === 'nb-no'}
                onChange={() => navigate(replaceLocale(pathname, 'nb-no'))}
              />
            </div>
          </Nav.Group>
        </>
      }
    >
      {children}
    </Nav>
  )
}
export default Navigation
