const supportedLocales = ['en-us', 'nb-no']
const defaultLocale = supportedLocales[0]
const findFirstPartOfPath = /^\/([^/]*)/

// replaceLang('/whatever/path/string', 'nb-no') // '/nb-no/path/string'
const replaceLocale = (url: string, lang: string) =>
  url.replace(findFirstPartOfPath, '/' + lang)

const getLocaleFromPath = (path: string) => {
  const matches = path.match(findFirstPartOfPath)
  if (!matches) throw new Error('getLangFromPath failed')
  return matches[1]
}

export { defaultLocale, supportedLocales, replaceLocale, getLocaleFromPath }
