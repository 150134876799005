import i18n from 'i18next' // i18n extension - nothing to do with next.js
import { initReactI18next } from 'react-i18next'

import enUsCommon from '../locales/en-US/common.json'
import nbNoCommon from '../locales/nb-NO/common.json'

import enUsHome from '../locales/en-US/home.json'
import nbNoHome from '../locales/nb-NO/home.json'

const resources = {
  'en-US': {
    common: enUsCommon,

    home: enUsHome
  },
  'nb-NO': {
    common: nbNoCommon,

    home: nbNoHome
  }
}

// i18n Manager seems like a solid free cross-platform desktop app for handling translations
// https://electronjs.org/apps/i18n-manager

// lazy load translation files when needed
// apparently causes app to refresh
// const backend = resourcesToBackend((locale, namespace, callback) => {
//   import(`../locales/${locale}/${namespace}.json`)
//     .then(resources => {
//       callback(null, resources)
//     })
//     .catch(error => {
//       callback(error, null)
//     })
// })

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // language code will be set by URL (App.tsx)
    lng: 'en-US',
    fallbackLng: 'en-US',
    keySeparator: false, // we do not use keys in form object.welcome
    nsSeparator: false, // we do not use keys in form namespace:welcome
    ns: 'common',
    fallbackNS: 'common',
    // uncomment following line to enable debug log while in dev mode
    // debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false // react already prevents xss
    }
  })

export default i18n
