const ErrorPage = () => {
  return (
    <>
      <h1 className='bf-h1'>An error has occured</h1>
      <p className='bf-p'>Our developers have been notified</p>
    </>
  )
}

export default ErrorPage
