import { useEffect, useRef } from 'react'
import * as Sentry from '@sentry/react'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Bifrost, enUS, nbNO } from '@intility/bifrost-react'

// global css files from external packages
import '@intility/bifrost-react/dist/bifrost-app.css'

// locale configuration
import 'utils/i18n.config'
import Navigation from './Navigation'
import { supportedLocales } from 'utils/locale'
import ErrorPage from './ErrorPage'
import Home from './Home'
import Redirect from './Redirect'

const useValidateLocale = () => {
  const { locale } = useParams<'locale'>()
  const location = useLocation()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const validLocale = ['en-us', 'nb-no'].includes(locale ?? '')

  useEffect(() => {
    if (!validLocale) {
      navigate('/en-us' + location.pathname, { replace: true })
    }
  }, [validLocale, location.pathname, navigate])

  useEffect(() => {
    let loc = Array.isArray(locale) || !locale ? '' : locale

    if (supportedLocales.includes(loc)) {
      // todo: fix caps sensitivity in a better way (that supports additional languages...)
      i18n.changeLanguage(loc === 'nb-no' ? 'nb-NO' : 'en-US')
    }
  }, [locale, i18n])

  return validLocale
}

const App = () => {
  const { locale } = useParams<'locale'>()
  const validLocale = useValidateLocale()

  const localeRef = useRef<string>(locale ?? 'en-us')
  localeRef.current = locale ?? 'en-us'

  if (!validLocale) return null

  return (
    <Bifrost locale={locale === 'nb-no' ? nbNO : enUS}>
      <Navigation>
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/*' element={<Redirect to={'/'} />} />
          </Routes>
        </Sentry.ErrorBoundary>
      </Navigation>
    </Bifrost>
  )
}

export default App
